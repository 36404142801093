import service from '@/utils/request';

// @Tags PublicDNSBlRules
// @Summary update a public dns black rules
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param authcode query string true "authCode to public dns conf"
// @Param data body request.PubdnsBlRulesRequestParams true
// @Success 200 {object} response.Response "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /pubdnsblrules [GET]

export var getPubDnsBlRules = function getPubDnsBlRules(params) {
  return service({
    url: '/pubdnsblrules',
    method: 'get',
    //  donNotShowLoading: false,
    params: params
  });
};

// @Tags PublicDNSBlRules
// @Summary Create a new public dns black rules
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param authcode query string true "authCode to public dns conf"
// @Param data body request.PubdnsBlRulesRequestParams true
// @Success 200 {object} response.Response "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /pubdnsblrules [post]

export var createPubDnsBlRules = function createPubDnsBlRules(data, authcode) {
  return service({
    url: '/pubdnsblrules?authcode=' + authcode,
    method: 'post',
    //  donNotShowLoading: false,
    data: data
  });
};

// @Tags PublicDNSBlRules
// @Summary update a public dns black rules
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param authcode query string true "authCode to public dns conf"
// @Param data body request.PubdnsBlRulesRequestParams true
// @Success 200 {object} response.Response "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /pubdnsblrules/updaterule/:id [put]

export var updatePubDnsBlRules = function updatePubDnsBlRules(data, authcode) {
  return service({
    url: "/pubdnsblrules/updaterule/" + data.ID + '?authcode=' + authcode,
    method: 'put',
    //  donNotShowLoading: false,
    data: data
  });
};

// @Tags PublicDNSBlRules
// @Summary delete a public dns black rules
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param authcode query string true "authCode to public dns conf"
// @Param data body request.PubdnsBlRulesRequestParams true
// @Success 200 {object} response.Response "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /pubdnsblrules/:id [delete]

export var deletePubDnsBlRules = function deletePubDnsBlRules(data, authcode) {
  return service({
    url: "/pubdnsblrules/" + data.ID + '?authcode=' + authcode,
    method: 'delete',
    //  donNotShowLoading: false,
    data: data
  });
};

// @Tags PublicDNSBlRules
// @Summary update priority of a public dns black rules
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PubdnsBlRulesPriorityParams true
// @Success 200 {object} response.Response "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /pubdnsblrules/updaterulepriority/:id [put]

export var updateRulePriority = function updateRulePriority(data) {
  return service({
    url: "/pubdnsblrules/updaterulepriority/" + data.ID,
    method: 'PUT',
    //  donNotShowLoading: false,
    data: data
  });
};